<template>
    <Modal :modal-title="activeLocation.name"
           :dialog="dialog"
           max-width="1600px"
           @close="close"
           :closeIcon="true"
           data-test="modal"
           @visibility="visibility($event)">
        <template slot="subtitle">
            <a class="text--s"
               @click="close"><v-icon>{{ ICONS.SINGLE_LEFT }}</v-icon>{{ $t('buttons.back_to_site') }}</a>
        </template>

        <template slot="content">
            <v-layout justify-end
                      v-if="activeLocation.special_attention">
                <span class="font-weight-bold red--text text--m mb-2">{{ $t('labels.special_attention') }}</span>
            </v-layout>
            <v-row>
                <v-col md="8"
                       sm="12"
                       cols="12">
                    <v-sheet color="white"
                             class="pa-4"
                             elevation="1">
                        <InformationItem :title="$t('labels.location_code')"
                                         name="code"
                                         ref="code"
                                         @edit="showEditForm"
                                         :text="activeLocation.code"></InformationItem>
                        <InformationItem :title="$t('labels.description')"
                                         name="description"
                                         ref="description"
                                         @edit="showEditForm"
                                         :text="activeLocation.description"></InformationItem>
                        <InformationItem :title="$t('labels.lat')"
                                         name="latitude"
                                         ref="latitude"
                                         @edit="showEditForm"
                                         :text="activeLocation.latitude"></InformationItem>
                        <InformationItem :title="$t('labels.long')"
                                         name="longitude"
                                         ref="longitude"
                                         @edit="showEditForm"
                                         :text="activeLocation.longitude"></InformationItem>
                        <InformationItem :title="$t('labels.qr_code')"
                                         :clickable="false"
                                         name="qr_code">
                            <template>
                                <PreviewImage v-if="activeLocation.qr_code_svg"
                                              :iconed="true"
                                              :icon="ICONS.QR_CODE"
                                              max-width="400px"
                                              :src="qrCodeSrc"></PreviewImage>
                            </template>
                        </InformationItem>
                        <InformationItem :title="$t('labels.nfc_tag')"
                                         name="nfccode"
                                         ref="nfccode"
                                         @edit="showEditForm"
                                         :text="activeLocation.nfccode"></InformationItem>
                        <InformationItem :title="$t('labels.beacon_identifier')"
                                         name="beacon_identifier"
                                         ref="beacon_identifier"
                                         data-test="showEditFormText"
                                         @edit="showEditForm"
                                         :text="activeLocation.beacon_identifier"></InformationItem>
                        <InformationItem :title="$t('labels.templates')"
                                         name="templatesForAccess"
                                         ref="templatesForAccess"
                                         data-test="showEditFormText"
                                         @edit="showEditForm"
                                         >
                            <template>
                                <ul v-if="activeLocation.pointTemplates.length > 0"
                                    class="list">
                                    <li v-for="(el,i) in activeLocation.pointTemplates.split(',')" :key="i">{{el}}</li>
                                </ul>
                            </template>
                        </InformationItem>
                        <InformationItem :title="$t('labels.incident_type')"
                                         name="incidentTypesForAccess"
                                         ref="incidentTypesForAccess"
                                         data-test="showEditFormText"
                                         @edit="showEditForm">
                            <template>
                                <ul v-if="activeLocation.pointIncidentTypes.length > 0"
                                    class="list">
                                    <li v-for="(el,i) in activeLocation.pointIncidentTypes.split(',')" :key="i">{{el}}</li>
                                </ul>
                            </template>
                        </InformationItem>
                        <InformationItem :title="$t('labels.inspectors')"
                                         name="locationInspectors"
                                         ref="locationInspectors"
                                         data-test="showEditFormText"
                                         @edit="showEditForm">
                            <template>
                                <ul v-if="activeLocation.pointInspectors.length > 0"
                                    class="list">
                                    <li v-for="(el,i) in activeLocation.pointInspectors.split(',')" :key="i">{{el}}</li>
                                </ul>
                            </template>
                        </InformationItem>
                        <InformationItem :title="$t('labels.point_persons')"
                                         name="pointPersons"
                                         ref="pointPersons"
                                         data-test="showEditFormText"
                                         @edit="showEditForm">
                            <template>
                                <ul v-if="activeLocation.pointPersons.length > 0"
                                    class="list">
                                    <li v-for="(el,i) in activeLocation.pointPersons.split(',')" :key="i">{{el}}</li>
                                </ul>
                            </template>
                        </InformationItem>

                        <v-layout justify-end>
                            <v-btn color="secondary"
                                   v-if="canEditLocation"
                                   data-test="showEditFormBtn"
                                   @click="showEditForm">{{ $t('buttons.edit') }}</v-btn>
                        </v-layout>
                    </v-sheet>
                </v-col>

                <v-col md="4"
                       sm="12"
                       cols="12"
                       :style="mobileHeight">
                    <div style="height: 340px;">
                    <Map :lat="activeLocation.latitude"
                         :lng="activeLocation.longitude"></Map></div>
                </v-col>
            </v-row>
        </template>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import Modal from '@/components/templates/Modal';
import InformationItem from '@/components/customers/InformationItem';
import PreviewImage from '@/components/widgets/PreviewImage';
import Map from '@/components/widgets/Map';
import { ICONS } from '@/constants/icons';

export default {
    name: 'LocationDetails',
    components: { Modal, InformationItem, PreviewImage, Map },
    props: {
        dialog: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        ICONS
    }),
    computed: {
        ...mapState('manageLocation', [
            'activeLocation'
        ]),
        ...mapFields('manageLocation', [
            'locationFormVisibility',
            'editedLocationId',
            'showTab'
        ]),
        mobileHeight () {
            return this.$vuetify.breakpoint.smAndDown ? 'height: 250px' : null;
        },
        canEditLocation () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.LOCATION);
        },
        qrCodeSrc () {
            return this.activeLocation.qr_code_svg ? `data:image/svg+xml;base64,${this.activeLocation.qr_code_svg}` : '';
        }
    },
    methods: {
        close () {
            this.$emit('close');
        },
        visibility (event) {
            this.$emit('visibility', event);
        },
        showEditForm () {
            if (this.canEditLocation) {
                this.$store.dispatch('manageLocation/getActiveLocation', this.activeLocation.id)
                    .then(() => {
                        this.editedLocationId = this.activeLocation.id;
                        this.$store.commit('manageLocation/SET_LOCATION_DATA', this.activeLocation);
                        this.locationFormVisibility = true;
                    });
            }
        }
    }
};
</script>

<style scoped lang="scss">
    .list {
        padding-left: 0.7rem;
        & li {
            list-style: number;
        }
    }
</style>
