<template>
    <Modal :modal-title="activeSite.name"
           :dialog="dialog"
           max-width="1600px"
           @close="close"
           :closeIcon="true"
           data-test="modal"
           @visibility="visibility($event)">
        <template slot="subtitle">
            <a class="text--s" v-if="!hideLink"
               @click="close">
                <v-icon>{{ ICONS.SINGLE_LEFT }}</v-icon>
                {{ $t('buttons.back_to_customer') }}</a>
        </template>

        <template slot="content">
            <v-row>
                <v-col cols="12">
                    <v-layout justify-space-between
                              :wrap="$vuetify.breakpoint.xs">
                        <h3 class="font-weight-medium mb-3">
                            {{ $t('management.customers_and_sites.site_information.title') }}</h3>
                        <span v-if="activeSite.special_attention"
                              class="font-weight-bold red--text text--m">{{ $t('labels.special_attention') }}</span>
                    </v-layout>

                    <v-row>
                        <v-col md="8"
                               sm="12"
                               cols="12">
                            <v-sheet color="white"
                                     class="pa-4"
                                     elevation="1">
                                <v-layout align-stretch
                                          :wrap="$vuetify.breakpoint.xs">
                                    <v-flex sm6
                                            :class="{'bordered pr-4': $vuetify.breakpoint.smAndUp}">
                                        <InformationItem
                                            :title="$t('management.customers_and_sites.site_information.name')"
                                            name="site_name"
                                            @edit="showEditForm"
                                            data-test="textShowSiteFormButton"
                                            :text="activeSite.name"></InformationItem>
                                        <InformationItem
                                            :title="$t('management.customers_and_sites.customer_information.address')"
                                            @edit="showEditForm"
                                            name="address"
                                            :text="activeSite.address"></InformationItem>
                                        <InformationItem :title="$t('site_form.customers_with_access')"
                                                         @edit="showEditForm"
                                                         name="customers_with_access"
                                                         :text="activeSite.employees_with_access_list"></InformationItem>
                                        <InformationItem :title="$t('site_form.internal_id')"
                                                         name="external_identifier"
                                                         :text="activeSite.internal_identifier"></InformationItem>
                                        <InformationItem :title="$t('site_form.external_id')"
                                                         @edit="showEditForm"
                                                         name="external_identifier"
                                                         :text="activeSite.external_identifier"></InformationItem>
                                        <InformationItem :title="$t('labels.site_code')"
                                                         @edit="showEditForm"
                                                         name="place_code"
                                                         :text="activeSite.place_code"></InformationItem>
                                    </v-flex>
                                    <v-flex sm6
                                            :class="{'pl-4': $vuetify.breakpoint.smAndUp}">
                                        <InformationItem :title="$t('labels.remarks')"
                                                         @edit="showEditForm"
                                                         name="comment"
                                                         :text="activeSite.comment"></InformationItem>
                                        <InformationItem :title="$t('site_form.assigned_to_groups')"
                                                         name="assigned_to_groups"
                                                         :clickable="false"
                                                         :text="activeSite.place_groups_list"></InformationItem>
                                        <InformationItem :title="$t('labels.notification_email')"
                                                         @edit="showEditForm"
                                                         name="emailsForNotifications"
                                                         :text="activeSite.emailData"></InformationItem>
                                        <InformationItem :title="$t('labels.notification_phone')"
                                                         @edit="showEditForm"
                                                         name="phonesForNotifications"
                                                         :text="activeSite.phoneData"></InformationItem>
                                        <InformationItem :title="$t('labels.inform_all_notifications')"
                                                         @edit="showEditForm"
                                                         name="informAboutAll"
                                                         :text="informAnswer"></InformationItem>
                                    </v-flex>
                                </v-layout>
                                <v-layout justify-end>
                                    <v-btn color="secondary"
                                           v-if="canEditSite"
                                           data-test="showEditFormBtn"
                                           @click="showEditForm">{{ $t('buttons.edit') }}
                                    </v-btn>
                                </v-layout>
                            </v-sheet>
                            <SiteAttachmentDownload v-if="activeSite.file" :currentFile="activeSite.file" />
                        </v-col>
                        <v-col md="4"
                               sm="12"
                               cols="12">
                            <div style="height: 280px;" class="mb-5">
                                <Map v-if="dialog"
                                     :lat="latitude"
                                     :lng="longitude"></Map>
                            </div>
                            <v-layout align-center justify-space-between>
                                <TextField :label="$t('labels.lat')"
                                           name="latitude"
                                           readonly
                                           class="mr-2"
                                           :value="latitude"></TextField>
                                <TextField :label="$t('labels.long')"
                                           name="longitude"
                                           readonly
                                           class="ml-2"
                                           :value="longitude"></TextField>
                            </v-layout>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" class="mt-4">
                    <ManageLocation v-if="locationFormVisibility" data-test="ManageLocation"></ManageLocation>
                    <LocationsPerSite :edit="edit"></LocationsPerSite>
                </v-col>
            </v-row>
        </template>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import Modal from '@/components/templates/Modal';
import ManageLocation from '@/components/customers/manageLocation/ManageLocation';
import InformationItem from '@/components/customers/InformationItem';
import LocationsPerSite from '@/components/customers/locationsPerSite/LocationsPerSite';
import Map from '@/components/widgets/Map';
import { ICONS } from '@/constants/icons';
import TextField from '@/components/widgets/forms/TextField';
import SiteAttachmentDownload from '@/components/customers/manageSite/SiteAttachmentDownload';

export default {
    name: 'SiteDetails',
    components: { Modal, InformationItem, LocationsPerSite, Map, TextField, ManageLocation, SiteAttachmentDownload },
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        hideLink: {
            type: Boolean,
            required: false
        },
        edit: {
            type: Boolean,
            'default': false
        }
    },
    data: () => ({
        ICONS
    }),
    computed: {
        ...mapState('manageSite', [
            'activeSite'
        ]),
        ...mapFields('manageSite', [
            'siteFormVisibility',
            'editedSiteId',
            'showTab',
            'activeSite.latitude',
            'activeSite.longitude'
        ]),
        ...mapFields('manageLocation', [
            'locationFormVisibility'
        ]),
        canEditSite () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.SITE);
        },
        informAnswer () {
            return this.activeSite.inform_all_inspections ? this.$t('answers.yes') : this.$t('answers.no');
        }
    },
    methods: {
        close () {
            this.$emit('close');
        },
        visibility (event) {
            this.$emit('visibility', event);
        },
        showEditForm (tab = null) {
            if (this.canEditSite) {
                this.editedSiteId = this.activeSite.id;
                this.showTab = tab;
                this.$store.commit('manageSite/SET_SITE_DATA', this.activeSite);
                this.siteFormVisibility = true;
            }
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/_variables";

.bordered {
    border-right: 1px solid $grey_lighten_2;
}
</style>
