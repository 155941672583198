<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <DefaultTable @edit-item="showEditForm($event)"
                      :setup="tableSetup"
                      @delete-item="deleteItem($event)"
                      ref="locationsTable">
            <template #fetchData>
                <v-row class="mx-0 my-3">
                    <a class="mr-3"
                       @click="updateTable">
                        <v-icon size="20">{{ ICONS.REFRESH }}</v-icon>
                        {{ $t('buttons.refresh_list') }}
                    </a>
                </v-row>
            </template>
            <template #search>
                <v-col cols="12" md="6">
                    <TextField v-model.trim="nfc"
                               clearable
                               @click:clear="nfc = ''"
                               label="NFC"
                               slotName="append">
                        <template slot="slotContent">
                            <v-icon color="medium_text">{{ ICONS.SEARCH }}</v-icon>
                        </template>
                    </TextField>
                </v-col>
            </template>
            <template v-slot:item.actions="{ item }">
                <div>
                    <HintingIcon :icon="ICONS.DETAILS"
                                 :tooltip="$t('actions.view')"
                                 :id="item.id"
                                 class-name="mr-2"
                                 @click="handleView(item)"></HintingIcon>
                    <HintingIcon :icon="ICONS.EDIT"
                                 :tooltip="$t('actions.edit')"
                                 v-if="canEditLocation"
                                 @click="editItem(item)"
                                 class-name="mr-2"></HintingIcon>

                    <ButtonWithConfirmation :item-id="item.id"
                                            v-if="canRemoveLocation"
                                            :callback="deleteLocation"></ButtonWithConfirmation>

                    <GetFile :with-title="false"
                             @get-file="getQRCode(item.id)">
                        <template slot="icon">
                            <HintingIcon :icon="ICONS.QR_CODE"
                                         :tooltip="$t('management.customers_and_sites.locations_table.actions.qr')"
                                         :id="item.id"></HintingIcon>
                        </template>
                    </GetFile>
                </div>
            </template>
        </DefaultTable>
        <ManageLocation @close-manage="updateTable" v-if="locationFormVisibility"></ManageLocation>
        <LocationDetails v-if="showLocationDetails"
                         :dialog="showLocationDetails"
                         @visibility="showLocationDetails = $event"
                         @close="close"></LocationDetails>

    </div>
</template>

<script>
import DefaultTable from '@/components/DefaultTable';
import { mapState } from 'vuex';
import HintingIcon from '@/components/widgets/HintingIcon';
import { ICONS } from '@/constants/icons';
import TextField from '@/components/widgets/forms/TextField';
import { mapFields } from 'vuex-map-fields';
import ManageLocation from '@/components/customers/manageLocation/ManageLocation';
import ButtonWithConfirmation from '@/components/widgets/buttons/ButtonWithConfirmation';
import GetFile from '@/components/widgets/buttons/GetFile';
import LocationDetails from '@/components/customers/LocationDetails';

export default {
    name: 'Locations',
    components: {
        DefaultTable,
        HintingIcon,
        ManageLocation,
        GetFile,
        ButtonWithConfirmation,
        TextField,
        LocationDetails
    },
    data: () => ({
        ICONS,
        siteId: null,
        nfc: null,
        defaultParams: null,
        showLocationDetails: false
    }),
    computed: {
        ...mapState('manageLocation', [
            'activeLocation'
        ]),
        ...mapFields('manageLocation', [
            'locationFormVisibility',
            'editedLocationId',
            'showTab'
        ]),
        canEditLocation () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.LOCATION);
        },
        canRemoveLocation () {
            return this.$can(this.$abilityActions.DELETE, this.$abilitySubjects.LOCATION);
        },
        additionalParams () {
            if (this.nfc) {
                return {
                    'filter[nfccode][match]': this.nfc,
                    ...this.defaultParams
                };
            } else return null;
        },
        tableSetup () {
            return {
                title: this.$t('management.customers_and_sites.locations_table.title'),
                deleteInfo: this.$t('messages.delete_confirmation'),
                searchBy: 'name',
                headers: [
                    {
                        text: 'No',
                        value: 'seq',
                        sortable: false
                    },
                    {
                        text: 'id',
                        value: 'id',
                        sortable: false
                    },
                    {
                        text: this.$t('labels.name'),
                        value: 'name',
                        icon: false,
                        sortable: true
                    },
                    {
                        text: this.$t('labels.city'),
                        value: 'city',
                        icon: false,
                        sortable: false
                    },
                    {
                        text: this.$t('labels.street_and_number'),
                        value: 'street_and_number',
                        icon: false,
                        sortable: false
                    },
                    {
                        text: this.$t('labels.timezone'),
                        value: 'timezone',
                        icon: false,
                        sortable: false
                    },
                    {
                        text: 'Beacon',
                        value: 'beacon_identifier',
                        icon: false,
                        sortable: false
                    },
                    {
                        text: 'NFC',
                        value: 'nfccode',
                        icon: false,
                        sortable: false
                    },
                    {
                        text: 'QR',
                        value: 'code',
                        icon: false,
                        sortable: false
                    },
                    {
                        text: this.$t('labels.actions'),
                        value: 'actions',
                        align: 'end',
                        width: '140px',
                        sortable: false
                    }
                ],
                endpoint: 'locations',
                customItems: this.mapItems,
                fetchParams: this.additionalParams,
                ability: {
                    c: false,
                    r: false,
                    u: true,
                    d: true
                },
                sortable: {
                    'name': 'desc'
                },
                sortingKeys: {
                    'name': 'name'
                }
            };
        }
    },
    methods: {
        mapItems (items) {
            return items.map(e => {
                return {
                    id: e.id,
                    ...e.attributes,
                    placeId: this.getId(e.relationships.place.links.related) || null
                };
            });
        },
        getId (str) {
            return str.substring(str.lastIndexOf('/') + 1) || null;
        },
        close () {
            this.$store.dispatch('manageLocation/cleanActiveLocationInformation');
            this.showLocationDetails = false;
            this.$store.dispatch('manageSite/cleanActiveSiteInformation');
        },
        handleView (item) {
            this.$store.dispatch('manageLocation/getActiveLocation', item.id)
                .then(() => {
                    this.showLocationDetails = true;
                });
        },
        getActiveSite (id) {
            return this.$store.dispatch('manageSite/getActiveSite', id);
        },
        editItem (item) {
            this.getActiveSite(item.placeId);
            this.$store.dispatch('manageLocation/getActiveLocation', item.id)
                .then(() => {
                    this.$store.commit('manageLocation/SET_LOCATION_DATA', this.activeLocation);
                    this.editedLocationId = item.id;
                    this.locationFormVisibility = true;
                });
        },
        updateTable () {
            this.$refs.locationsTable.fetchData();
        },
        deleteLocation (id) {
            return this.$store.dispatch('manageLocation/deleteLocation', id)
                .then(() => {
                    this.updateTable();
                });
        },
        getQRCode (id) {
            return this.$store.dispatch('manageLocation/getLocationQrCode', id);
        }
    },
    watch: {
        nfc: {
            handler (val) {
                if (val) {
                    this.$refs.locationsTable.setup.fetchParams = this.additionalParams;
                    this.$refs.locationsTable.fetchData();
                } else {
                    this.$refs.locationsTable.setup.fetchParams = this.defaultParams;
                    this.$refs.locationsTable.fetchData();
                }
            },
            deep: true
        }
    }
};
</script>
