<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-data-table :headers="headers"
                      :items="sitesPerCustomers"
                      disable-pagination
                      dense
                      hide-default-footer
                      @dblclick:row="showRowDetails"
                      class="elevation-1">
            <template v-slot:item.name="{ item }">
                <span class="clickable"
                      data-test="handleViewNameText"
                      @click="handleView(item)">{{ item.name }}</span>
            </template>

            <template v-slot:item.special_attention="{ item }">
                <v-layout justify-center>
                    <v-icon color="red"
                            v-if="item.special_attention">{{ ICONS.CHECK }}</v-icon>
                </v-layout>
            </template>

            <template v-slot:item.actions="{ item }">
                <div data-test="actionIcons">
                    <HintingIcon :icon="ICONS.DETAILS"
                                 :tooltip="$t('actions.view')"
                                 class-name="mr-2"
                                 data-test="handleViewIcon"
                                 @click="handleView(item)"></HintingIcon>

                    <HintingIcon :icon="ICONS.EDIT"
                                 v-if="canEditSite"
                                 :tooltip="$t('actions.edit')"
                                 @click="showEditForm(item)"
                                 data-test="showEditFormIcon"
                                 class-name="mr-2"></HintingIcon>

                    <ButtonWithConfirmation :item-id="item.id"
                                  v-if="canRemoveSite"
                                  :callback="deleteSite"></ButtonWithConfirmation>
                    <GetAllPointsQrCodes :customer-id="activeCustomer.id"></GetAllPointsQrCodes>
                </div>
            </template>
        </v-data-table>
        <SiteDetails :dialog="showSiteDetails"
                     data-test="siteDetails"
                     :edit="edit"
                     v-if="showSiteDetails"
                     @close="closeSiteDetails"
                     @visibility="showSiteDetails = $event"></SiteDetails>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import HintingIcon from '@/components/widgets/HintingIcon';
import SiteDetails from '@/components/customers/SiteDetails';
import ButtonWithConfirmation from '@/components/widgets/buttons/ButtonWithConfirmation';
import { ICONS } from '@/constants/icons';
import GetAllPointsQrCodes from '@/components/customers/GetAllPointsQrCodes';

export default {
    name: 'sitesPerCustomerTable',
    components: { HintingIcon, SiteDetails, ButtonWithConfirmation, GetAllPointsQrCodes },
    props: {
        edit: {
            type: Boolean,
            'default': false
        }
    },
    data: () => ({
        ICONS
    }),
    computed: {
        ...mapState('customers', [
            'sitesPerCustomers'
        ]),
        ...mapState('manageCustomer', [
            'activeCustomer'
        ]),
        ...mapState('manageSite', [
            'activeSite'
        ]),
        ...mapFields('manageSite', [
            'siteFormVisibility',
            'editedSiteId',
            'showSiteDetails'
        ]),
        isLgBreakpoint () {
            return this.$vuetify.breakpoint.lgAndUp;
        },
        headers () {
            return [
                {
                    text: this.$t('management.customers_and_sites.sites_table.headers.name'),
                    value: 'name',
                    sortable: false
                },
                {
                    text: this.$t('management.customers_and_sites.sites_table.headers.city'),
                    value: 'city',
                    sortable: false
                },
                {
                    text: this.$t('management.customers_and_sites.sites_table.headers.address'),
                    value: 'street_and_number',
                    sortable: false
                },
                {
                    text: this.$t('management.customers_and_sites.sites_table.headers.locations_amount'),
                    value: 'points_count',
                    sortable: false
                },
                {
                    text: this.$t('management.customers_and_sites.sites_table.headers.actions'),
                    value: 'actions',
                    sortable: false,
                    width: '180px'
                }
            ];
        },
        canEditSite () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.SITE);
        },
        canRemoveSite () {
            return this.$can(this.$abilityActions.DELETE, this.$abilitySubjects.SITE);
        }
    },
    methods: {
        getActiveSite (id) {
            return this.$store.dispatch('manageSite/getActiveSite', id);
        },
        handleView (item) {
            this.getActiveSite(item.id)
                .then(() => {
                    this.showSiteDetails = true;
                });
        },
        closeSiteDetails () {
            this.$store.dispatch('manageSite/cleanActiveSiteInformation');
            this.showSiteDetails = false;
        },
        showEditForm (item) {
            this.getActiveSite(item.id)
                .then(() => {
                    this.$store.commit('manageSite/SET_SITE_DATA', this.activeSite);
                    this.editedSiteId = item.id;
                    this.siteFormVisibility = true;
                });
        },
        getSitesPerCustomer () {
            return this.$store.dispatch('customers/getSitesPerCustomer', this.activeCustomer.id);
        },
        deleteSite (id) {
            return this.$store.dispatch('manageSite/deleteSite', id)
                .then(() => {
                    this.getSitesPerCustomer();
                });
        },
        showRowDetails (event, { item }) {
            this.handleView(item);
        }
    },
    created () {
        const _width = this.isLgBreakpoint ? '300px' : '150px';
        this.$set(this.headers.find(el => el.value === 'points_count'), 'width', _width);
    }
};
</script>

<style scoped>

</style>
