<template>
    <v-sheet color="white"
             class="pt-4 pb-0 px-0"
             elevation="1">
        <ValidationObserver ref="obs">
            <v-layout align-stretch :wrap="$vuetify.breakpoint.xs">
                <v-flex sm5 class="px-4">
                    <TextField :label="$t('customer_form.name')"
                               :rules="{ max: 50, required: true }"
                               ref="name"
                               name="name"
                               v-model="name">

                    </TextField>
                    <TextField :label="$t('customer_form.email')"
                               name="report_email"
                               ref="report_email"
                               :rules="{ max: 40, email: true }"
                               v-model="reportEmail"></TextField>
                    <v-layout justify-space-between>
                        <Autocomplete :label="$t('labels.city')"
                                      name="zip_code"
                                      rules="required"
                                      :no-filter="true"
                                      ref="zip_code"
                                      :items="filterableZipCodes"
                                      :loading="isCitiesLoading"
                                      :search-input.sync="searchCitiesAndZipCode"
                                      v-model="cityId"
                                      return-object
                                      slot-name="append-item"
                                      @load-more-items="getCitiesLazyLoading(cityId)"
                                      @input="updateZipCode($event)"
                        >
                        </Autocomplete>
                        <v-btn color="secondary" outlined
                               v-if="canAddCity"
                               @click="openForm"
                        >{{ $t('labels.new_localization') }}
                        </v-btn>
                    </v-layout>

                    <TextField :label="$t('customer_form.street_and_number')"
                               name="streetAndNumber"
                               ref="streetAndNumber"
                               :rules="{ max: 70, required: true }"
                               v-model="streetAndNumber"></TextField>
                    <v-btn color="secondary"
                           outlined
                           :loading="loader"
                           :disabled="!streetAndNumber"
                           @click="getCoordinates">{{ $t('permissions.refresh_points_geolocation') }}
                    </v-btn>
                </v-flex>
                <v-flex sm3
                        class="px-4">
                    <TextField :label="$t('customer_form.external_id')"
                               name="external_identifier"
                               ref="external_identifier"
                               :rules="{ max: 40 }"
                               data-test="external_id"
                               v-model="externalIdentifier"></TextField>
                    <TextField :label="$t('customer_form.internal_id')"
                               name="internal_identifier"
                               :rules="{ max: 40 }"
                               ref="internal_identifier"
                               v-model="internalIdentifier"></TextField>
                    <TextField :label="getIdentificationLabel(1)"
                               name="identification_1"
                               ref="identification_1"
                               :rules="{ max: 40 }"
                               v-model="identification_1"></TextField>
                    <TextField :label="getIdentificationLabel(2)"
                               name="identification_2"
                               ref="identification_2"
                               :rules="{ max: 40 }"
                               v-model="identification_2"></TextField>
                    <TextField :label="getIdentificationLabel(3)"
                               name="identification_3"
                               ref="identification_3"
                               :rules="{ max: 40 }"
                               v-model="identification_3"></TextField>
                    <TextField :label="getIdentificationLabel(4)"
                               name="identification_4"
                               ref="identification_4"
                               :rules="{ max: 40 }"
                               v-model="identification_4"></TextField>
                </v-flex>
                <v-flex sm4 class="px-4">
                    <div style="height: 340px;" class="mb-5">
                        <Map draggable
                             :lat="latitude"
                             :lng="longitude"
                             @update-coordinates="updateCoordinates($event)"></Map>
                    </div>
                    <v-layout align-center justify-space-between>
                        <TextField :label="$t('labels.lat')"
                                   name="latitude"
                                   class="mr-2"
                                   rules="required|between:-90,90"
                                   v-model="latitude"></TextField>
                        <TextField :label="$t('labels.long')"
                                   name="longitude"
                                   rules="required|between:-180,180"
                                   class="ml-2"
                                   v-model="longitude"></TextField>
                    </v-layout>
                </v-flex>
            </v-layout>
        </ValidationObserver>
        <ManageCity @fetch="fetchCities"></ManageCity>
    </v-sheet>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapState, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import TextField from '@/components/widgets/forms/TextField';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import Map from '@/components/widgets/Map';
import focusedFields from '@/mixins/focusedFields';
import debouncedCities from '@/mixins/debounced/debouncedCities';
import debouncedReturnCoordinates from '@/mixins/debounced/debouncedReturnCoordinates';
import ManageCity from '@/components/cities/manageCity/ManageCity';

export default {
    name: 'ManageCustomerForm',
    components: { TextField, Autocomplete, Map, ValidationObserver, ManageCity },
    mixins: [focusedFields, debouncedCities, debouncedReturnCoordinates],
    data: () => ({
        loader: false
    }),
    computed: {
        ...mapFields('manageCustomer', [
            'customerData.name',
            'customerData.streetAndNumber',
            'customerData.cityId',
            'customerData.internalIdentifier',
            'customerData.externalIdentifier',
            'customerData.identification_1',
            'customerData.identification_2',
            'customerData.reportEmail',
            'customerData.identification_3',
            'customerData.identification_4',
            'customerData.zipCode',
            'customerData.latitude',
            'customerData.longitude',
            'customerFormVisibility'
        ]),
        ...mapFields('manageSite', [
            'siteFormVisibility',
            'siteData'
        ]),
        ...mapState('manageCustomer', [
            'activeCustomer'
        ]),
        ...mapGetters('cities', [
            'filterableCities',
            'filterableZipCodes'
        ]),
        isFormDirty () {
            return this.$refs.obs.flags.changed;
        },
        canAddCity () {
            return this.$can(this.$abilityActions.CREATE, this.$abilitySubjects.CITY);
        }
    },
    watch: {
        focusedField: {
            handler () {
                this.setFocus(this.$refs);
            }
        },
        customerFormVisibility: {
            handler (val) {
                if (val && this.activeCustomer !== null) {
                    this.getCities(parseInt(this.activeCustomer.city_id));
                    this.$nextTick(() => {
                        if (this.latitude === null || this.longitude === null) {
                            this.getCoordinates();
                        }
                    });
                }
            },
            immediate: true
        },
        siteFormVisibility: {
            handler (val) {
                if ((val === true) && this.siteData !== null) {
                    const cityId = parseInt(this.siteData.cityId);
                    this.getCities(!isNaN(cityId) ? cityId : null);
                } else if ((val === false) && this.activeCustomer !== null) {
                    const cityId = parseInt(this.activeCustomer.city_id);
                    this.getCities(parseInt(!isNaN(cityId) ? cityId : null));
                }
            },
            immediate: true
        }
    },
    methods: {
        updateCoordinates (event) {
            if (event) {
                this.latitude = event.lat;
                this.longitude = event.lng;
            }
        },
        openForm () {
            this.$refs.zip_code.isMenuActive = false;
            this.$store.dispatch('generalConfigurations/getCountries').then(() => {
                this.$store.commit('cities/SET_CITY_FORM_VISIBILITY', true);
            });
        },
        uniqueBy (arr, prop) {
            const uniq = {};
            return arr.filter(obj => !uniq[obj[prop]] && (uniq[obj[prop]] = true));
        },
        resetValidation () {
            return this.$refs.obs.reset();
        },
        fetchCities () {
            this.searchZipCodes = this.zipCode;
        },
        updateZipCode (event) {
            if (event) {
                this.zipCode = event.zip_code;
                this.cityId = event.id;
            } else {
                this.zipCode = '';
                this.cityId = '';
            }
        },
        validate () {
            return this.$refs.obs.validate();
        },
        getIdentificationLabel (i) {
            if (this.activeCustomer && this.activeCustomer.identification_with_label) {
                return this.activeCustomer.identification_with_label[`identification_${i}`].label;
            }
            return this.$t(`customer_form.aux_id${i}`);
        },
        setCoordinates (data) {
            this.latitude = data.latitude;
            this.longitude = data.longitude;
        },
        getCoordinates () {
            this.loader = true;
            if (this.cityId && this.streetAndNumber) {
                this.returnCoordinates()
                    .then(({ data }) => {
                        this.setCoordinates(data);
                    })
                    .finally(() => { this.loader = false; });
            }
        }
    },
    mounted () {
        this.setFocus(this.$refs);
    }
};
</script>

<style scoped lang="scss">
    .mt-1 {
        margin-top: 6px !important;
    }
</style>
